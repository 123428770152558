// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: 'dev',
  production: false,
  api: 'https://izzo-api-dev.herokuapp.com',
  websiteURL: 'https://www-dev.izzo-app.com',
  adminURL: 'https://admin-dev.izzo-app.com',
  stripeKey:
    'pk_test_51Mb4X1Ga89ImEHjiVreBVaMqUetkvPMGW4MFlauNnGbIVGbg5i6wj8INMED8e7Oi4G187EL6yTLw45fpdnJxFBxZ0036XxbtgD',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
